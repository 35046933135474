<template>
  <v-row justify="center">
    <v-dialog
        v-model="shower"
        max-width="500px"
      >
        <v-card>
          <v-card-title>
           ชื่อบริษัท
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-col class="d-flex" cols="12" sm="6">           
              <v-text-field
                v-model="zoom"
                :label="zoom"
                solo
                dense
              ></v-text-field>
              <v-select
                :items="sizeStorage"
                label="size"
                dense
                outlined
              ></v-select>
            </v-col>
          </v-card-text>
          <v-card-text>
            <v-slider
              v-model="zoom"
              append-icon="mdi-plus"
              prepend-icon="mdi-minus"
              @click:append="zoomIn"
              @click:prepend="zoomOut"
              track-color="grey"
            ></v-slider>
          </v-card-text>
          <v-card-text>
            <v-progress-linear
              height="10"
              :value="zoom"
              striped
              reactive                    
            ></v-progress-linear></v-card-text>
          <v-card-subtitle class="text-right"> in {{zoom}} GB</v-card-subtitle>
          <!-- <v-card-text>
          <v-progress-linear
            color="light-blue"
            height="10"
            value="10"
            striped
          ></v-progress-linear>
          </v-card-text> -->
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
             color="red"           
             outlined
             @click="$emit('closedialog')"
             class="text-right"
            >
              Close
            </v-btn>
            <v-btn
             color="primary"           
             class="text-right"
            >
              ตกลง
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
  </v-row>
</template>
<script>
  export default {
    props : ["show"],
    data () {
      return {
        dialog: false,
        zoom: 0,
        sizeStorage:['KB','GB','TB'],
      }
    },

   computed: {
    shower: {
      get() {
        if (this.show === true) {
          // this.loaddata();
          this.$emit('closeDrag')
        }
        return this.show;
      }
    }
  },
  methods: {
     zoomOut () {
        this.zoom = (this.zoom - 1) || 0
      },
      zoomIn () {
        this.zoom = (this.zoom + 1) || 1000
      },
  }
  }
</script>
